html,
body {
  margin: 0;
}

nav {
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}

.logo img {
  /* margin: 17.5px 0;
  max-height: 80px;
  width: 80%; */
  margin: 8px;
  width: 80%;
  height: 80px;
}

ul {
  display: flex;
  align-items: center;
}

ul li {
  margin: 0 25px;
}

a.active {
  text-decoration: underline 5px #f5777e;
}

.menu-burger {
  width: 20px;
  display: none;
  position: absolute;
  right: 20px;
}

@media screen and (max-width: 966px) {
  nav .mobile-menu {
    margin-left: 0;
  }

  nav {
    padding: 0;
  }

  .nav-links {
    top: 0;
    left: 0;
    position: absolute;
    background-color: white;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -100%;
    transition: all 0.5s ease;
  }

  ul {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  ul li {
    margin: 32px;
    font-size: 1.4em;
    font-weight: 700;
    text-transform: uppercase;
  }

  a {
    color: rgb(45, 44, 44);
  }
  a.active {
    text-decoration: underline 10px #f5777e;
  }

  .menu-burger {
    font-size: 1.8em;
    display: block;
    cursor: pointer;
  }

  .card-container {
    flex-direction: column;
  }
}
