.background img {
  position: fixed;
  object-fit: cover;
  width: 100%;
  max-height: 500px;
  opacity: 0.2;
  z-index: -1;
}

.description-container {
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
  margin: 32px 0;
}

.title-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.text-description {
  max-width: 700px;
}

.portrait-img {
  max-width: 300px;
}

.portrait-img img {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}

.card-container {
  display: flex;
  align-items: center;
  gap: 100px;
  margin: 100px 0;
}

.card-text {
  max-width: 800px;
}
