footer {
  background-color: white;
  font-size: 0.8em;
  padding: 50px 16px;
}

.footer-container {
  margin: auto;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.footer-div {
  padding: 20px;
}

.footer-img {
  max-width: 80px;
}
