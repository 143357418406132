.page {
  max-width: 1200px;
  margin: auto;
  padding: 5% 32px;
  /* padding: 16px; */
}

.success {
  font-weight: 400;
  font-size: 12px;
  color: #1a5506;
  font-style: italic;
}

.hidden {
  visibility: hidden;
}
.visible {
  visibility: visible;
}

.map-container {
  width: 500px;
}

.page p {
  padding: 20px 0;
  line-height: 1.5;
}

.map-info-window > p {
  padding: 0;
}

.card {
  width: 250px;
  min-height: 180px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 16px;
  border-radius: 4px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}

.card-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 36px;
  margin: 100px 0;
}

.container-contact {
  margin: 32px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.container-input {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
}

form {
  max-width: 90%;
  padding: 16px;
  background: white;
  border-radius: 4px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  margin-bottom: 16px;
}

input {
  height: 35px;
}

.button {
  cursor: pointer;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 200px;
  height: 36px;
  padding: 20px 10px;
  background-color: #d81f2a;
  color: white;
  font-size: 0.8em;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 4px;
  border: 1px solid #d81f2a;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}

.icon-small {
  font-size: 1.5em;
}
.icon-medium {
  font-size: 2.5em;
}

.icon-white > path {
  color: #f1f3f5;
}
.icon-red > path {
  color: #d81f2a;
}
.icon-brown > path {
  color: #483a3a;
}
.icon-brown-clear > path {
  color: #b08268;
}
.icon-grey > path {
  color: #484848;
}
