* {
  margin: 0;
  padding: 0;
  color: #484848;
}

body {
  background-color: #f9f9f9;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

li {
  list-style-type: none;
}

h1 {
  color: #261e1e;
  /* font-size: 5vh; */
}

h2 {
  font-size: 3vh;
}
